/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #4ac1e0;
  --passed-bullet-background-gradient: linear-gradient(
      180deg,
      rgba(205, 157, 76, 0.2) 0%,
      rgba(152, 83, 37, 0.2) 100%
    ),
    linear-gradient(24.93deg, #f28b41 24.85%, #fec958 74.4%, #ffe666 97.48%);
  --remove-player-button-background: linear-gradient(#fe7b5e, #f32e2c);
  --status-background: linear-gradient(
    90deg,
    #131d39 0%,
    rgba(19, 29, 57, 0.501961) 100%
  );
  --big-button-background: #f3a33a;
  --unavailable-button-background: linear-gradient(
    24.93deg,
    #595959 24.85%,
    #bfbfbf 97.48%
  );
  --unavailable-button-border: linear-gradient(
    20deg,
    #a4aaba 17.77%,
    #d9d9d9 87.77%
  );
  --card-corner-background: linear-gradient(
    215deg,
    #f5c257 6.17%,
    #f5c257 26.42%,
    #f3a33a 81.61%,
    #f3a33a 93.83%
  );
  --card-corner-background-blue: linear-gradient(
    215deg,
    #76d9e4 6.17%,
    #76d9e4 26.42%,
    #4bb7c3 81.61%,
    #4bb7c3 93.83%
  );
  --focused-input-border: linear-gradient(
    20deg,
    #f28b41 17.77%,
    #ffe666 87.77%
  );
  --tab-background: linear-gradient(#fbfcfc, #fef6e4);
  --tab-bar: #37889b;
  --warning-background: rgba(9, 118, 131, 0.5);
  --blurred-answer: rgba(163, 216, 222, 0.5);
  --waiting-user-border: rgba(212, 165, 10, 0.2);
  --waiting-user-nick-background: rgba(255, 255, 255, 0.9);
  --modal-background: rgba(240, 147, 62, 0.9);
  --modal-background-blue: rgba(25, 71, 81, 0.9);
  --cities-list-background: linear-gradient(#ffdfb4, #ffffff);
  --blue-button-border: linear-gradient(#93effa, #4bb7c3);
  --info-modal-gradient-background: linear-gradient(#58c7d2, #00929a);
  --profile-slide-bottom-background: linear-gradient(#2f939e, #24858e);
  --profile-slide-top-background: linear-gradient(#75d0dc, #39a1ac);
  --test: #f6d38e;
  --light-yellow: #ffeb91;
  --dark-yellow: #fcc95d;
  --light-orange: #ffa600;
  --medium-orange: #f09835;
  --dark-orange: #f4b223;
  --dark-blue: #194751;
  --less-dark-blue: #0d6571;
  --more-medium-blue: #177f8c;
  --medium-blue: #41a8b4;
  --bright-blue: #75d0dc;
  --light-blue: #95d4e9;
  --less-light-blue: #90bec2;
  --white: #ffffff;
  --dark-grey: #8c8c8c;
  --light-grey: #bfbfbf;
  --light-peach: #fef1d8;
  --medium-peach: #ffd0aa;
  --dark-peach: #ffd48b;
  --static: #f6d38e;
  --success-color: #70d44b;
  --error-color: #ea522f;
  --dark-red: #a14800;
  --blue-text-shadow-color: #595959;

  --round-shadow: 0px 5px 8px 0px rgba(34, 60, 80, 0.61);
  --box-shadow-small: 0px 8px 8px 0px rgba(34, 60, 80, 0.65);
  --box-shadow-tiny: 0px 3px 3px 0px rgba(34, 60, 80, 0.85);
  --box-shadow-orange: -1.83697e-16px 6px 0px #d05b0d;
  --box-shadow-blue: -1.83697e-16px 6px 0px #057d81;
  --box-shadow-orange-light: -1.83697e-16px 6px 0px #e39445;
  --card-corner-shadow: -4.83333px 8.37158px 13px rgba(35, 1, 64, 0.529412);

  --red-text-shadow: #a14800 2px 2px 2px, #a14800 -2px -2px 2px,
    #a14800 -2px 2px 2px, #a14800 2px -2px 2px;
  --blue-text-shadow: #595959 0px 1px 0px;
  --blue-text-shadow-big: #595959 2px 2px 2px, #595959 -2px -2px 2px,
    #595959 -2px 2px 2px, #595959 2px -2px 2px;

  --blue-button-background: linear-gradient(#75d0dc, #41a8b4);
  --big-button-border-background: linear-gradient(#ffeb91, #e4811e);
  --google-button-border: linear-gradient(
    187.79deg,
    #ffaca5 10.32%,
    #d24235 145.78%
  );
  --pwd-rule-mark: linear-gradient(#ffeb91, #e4811e);

  --font-light: Gilroy-Light, sans-serif;
  --font-bold: Gilroy-ExtraBold, sans-serif;

  --transform: transform 250ms ease;

  --shining: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 12px 40px #054851,
    inset 0px 4px 12px 2px rgba(255, 255, 255, 0.5);
}
