@font-face {
  font-family: Gilroy-Light;
  src: url(../src/theme/fonts/Gilroy-Light.otf);
}

@font-face {
  font-family: Gilroy-ExtraBold;
  src: url(../src/theme/fonts/Gilroy-ExtraBold.otf);
}

html {
  --ion-font-family: var(--font-light);
}

body {
  background: var(--ion-background-color);
  font-family: var(--font-light);
}

/* tab-bar styles */
ion-tab-bar {
  position: absolute;
  bottom: 0;

  contain: none;

  width: 100%;
  height: 80px;

  --background: var(--tab-background);

  padding-bottom: 0;
}

ion-tab-button {
  padding: 5px 0;
  background: var(--tab-bar);
}

ion-tab-button .tab-join--custom {
  background-image: url('./icons/Join.png');
}
ion-tab-button .tab-home--custom {
  background-image: url('./icons/Home.png');
}
ion-tab-button .tab-journal--custom {
  background-image: url('./icons/Journal.png');
}
ion-tab-button .tab-profile--custom {
  background-image: url('./icons/Profile.png');
}
.tab-selected .tab-join--custom {
  background-image: url('./icons/Join.png');
}
.tab-selected .tab-home--custom {
  background-image: url('./icons/Home.png');
}
.tab-selected .tab-journal--custom {
  background-image: url('./icons/Journal.png');
}
.tab-selected .tab-profile--custom {
  background-image: url('./icons/Profile.png');
}

ion-tab-button ion-label {
  color: var(--white);
  font-family: var(--font-bold);
  margin: 0;
}

/* main page styles */
ion-toolbar {
  --background: transparent no-repeat static center;
  padding-top: 0 !important;
}
.toolbar-container {
  padding-top: 0;
}
.ion-padding {
  padding: 15px 40px;
}

div.swiper-pagination {
  display: none;
}
/* toast on challenge page */
ion-toast {
  --height: 90px;

  --background: var(--white);
  --color: var(--dark-orange);

  font-family: var(--font-bold);
  font-size: 25px;
  text-align: center;

  --border-radius: 10px;
  --border-width: 3px;
  --border-style: solid;
  --border-color: var(--light-peach);

  --box-shadow: var(--box-shadow-small);
}
ion-toast.profile-toast {
  font-size: 20px;
}
/* for all images */
img {
  display: block;
  object-fit: cover;

  width: 100%;
}
/* map styles */
div.mapboxgl-popup-content p {
  margin: 0;
}
div.mapboxgl-popup-content h3 {
  margin-top: 0;
}
div.mapboxgl-popup-content {
  padding: 8px 16px;

  font-family: var(--font-bold);
  font-size: 15px;
  background: var(--dark-orange);
  color: var(--white);
  box-shadow: var(--box-shadow-tiny);
  border-radius: 8px;
}
div.mapboxgl-popup-tip {
  display: none;
}
div.mapboxgl-popup-content button {
  display: none;
}
.mapboxgl-popup-close-button:hover {
  background-color: transparent;
  border: none !important;
}
button.mapboxgl-popup-close-button {
  border: transparent;
  outline: transparent;
}
div.mapboxgl-ctrl-top-left,
div.mapboxgl-ctrl-bottom-right {
  visibility: hidden;
}
div.mapboxgl-ctrl-attrib-inner a {
  color: var(--more-medium-blue);
}
button.mapboxgl-ctrl-attrib-button {
  opacity: 0;
}
/* styling marker for user position */
.current-position {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: var(--dark-orange);

  border-radius: 50%;
  border: 3px solid var(--light-orange);
}

button:focus,
input:focus,
label:focus {
  outline: none !important;
}
input:focus {
  outline-offset: 0 !important;
}
/* styling stop marker */
.stop-marker {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  font-family: var(--font-bold);
  font-size: 24px;

  color: #000;
  background-color: var(--white);

  border-radius: 12px;
}
.stop-marker-chosen {
  background-color: var(--light-yellow);
}
/* Home page icons size */

ion-header {
  display: flex;
  align-items: center;
  height: 70px;
  z-index: initial;
}
ion-content {
  --keyboard-offset: 0 !important;
}

.markdown p {
  margin: 0;
  font-size: 18px;
}

.mapbox-popup * {
  font-family: var(--font-light);
  font-size: 18px;
}

.mapbox-popup > h3 {
  font-family: var(--font-bold);
  margin-bottom: 0px;
}

.mapbox-popup > p {
  margin-top: 5px;
}

.more-medium-blue {
  background: var(--more-medium-blue);
  opacity: 0.5;
}

@media screen and (max-height: 667px), screen and (max-width: 374px) {
  ion-header {
    height: 55px;
  }
  .markdown p {
    font-size: 16px;
  }
}
